var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-text-input custom-dropdown"},[(_vm.header)?_c('div',{class:_vm.headerClass || 'field__subheader d-flex'},[(_vm.required)?_c('span',{staticClass:"field__subheader--required"},[_vm._v(" * ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.header)+" "),_vm._t("icon")],2):_vm._e(),(_vm.subheader)?_c('div',{staticClass:"field__subheader field__subheader_item pt-2"},[_vm._v(" "+_vm._s(_vm.subheader)+" ")]):_vm._e(),_c('validation-observer',{ref:"textInput"},[_c('validation-provider',{attrs:{"rules":{
        required: _vm.required
      },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(!_vm.autocomplete)?_c('v-select',{ref:"dropdown",class:_vm.dropdownClass || 'custom-dropdown mt-4',attrs:{"readonly":_vm.readonly,"value":_vm.value,"placeholder":_vm.placeholder,"multiple":_vm.multiple,"dense":"","items":_vm.items,"outlined":"","error-messages":errors,"menu-props":{
          offsetY: true,
          'content-class': 'custom-dropdown',
        },"item-value":_vm.itemValue || 'value',"item-text":_vm.itemText || 'label',"return-object":_vm.returnObject,"hint":_vm.hint,"persistent-hint":"","append-icon":"mdi-chevron-down mt-1"},on:{"change":(newValue) => _vm.$emit('change', newValue),"input":_vm.inputEvent},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(item.img)?[_c('img',{staticClass:"pr-1",attrs:{"src":item.img,"alt":item[_vm.itemText || 'label'],"height":"15"}}),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:(item.icon)?[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"13px"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:(_vm.short)?[(index === 0)?_c('span',{staticClass:"short-text",class:{'has-more': _vm.value.length > 1}},[_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]):_vm._e(),(index === 1)?_c('span',[_vm._v(" (+"+_vm._s(_vm.value.length - 1)+" others) ")]):_vm._e()]:[_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]]}},{key:"item",fn:function({ item }){return [(item.img)?[_c('img',{staticClass:"pr-1",attrs:{"src":item.img,"alt":item[_vm.itemText || 'label'],"height":"15"}}),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:(item.icon)?[_c('v-icon',{attrs:{"size":"13px"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:[_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]]}}],null,true)}):_c('v-autocomplete',{ref:"dropdown",class:_vm.dropdownClass || 'custom-dropdown mt-4',attrs:{"readonly":_vm.readonly,"value":_vm.value,"placeholder":_vm.placeholder,"multiple":_vm.multiple,"dense":"","items":_vm.items,"outlined":"","error-messages":errors,"menu-props":{
          offsetY: true,
          contentClass: 'custom-dropdown',
        },"item-value":_vm.itemValue || 'value',"item-text":_vm.itemText || 'label',"return-object":_vm.returnObject,"hint":_vm.hint,"persistent-hint":"","append-icon":"mdi-chevron-down mt-1"},on:{"change":(newValue) => _vm.$emit('change', newValue),"input":_vm.inputEvent},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index}){return [(item.img)?[_c('img',{staticClass:"pr-1",attrs:{"src":item.img,"alt":item[_vm.itemText || 'label'],"height":"15"}}),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:(item.icon)?[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"13px"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:(_vm.short)?[(index === 0)?_c('span',{staticClass:"short-text",class:{'has-more': _vm.value.length > 1}},[_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]):_vm._e(),(index === 1)?_c('span',[_vm._v(" (+"+_vm._s(_vm.value.length - 1)+" others) ")]):_vm._e()]:[_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]]}},{key:"item",fn:function({ item }){return [(item.img)?[_c('img',{staticClass:"pr-1",attrs:{"src":item.img,"alt":item[_vm.itemText || 'label'],"height":"15"}}),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:(item.icon)?[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"13px"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]:[_vm._v(" "+_vm._s(item[_vm.itemText || 'label'])+" ")]]}}])})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }